<template>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <span class="tituloBreadCrumb">Estás en:</span> 
    <li class="breadcrumb-item" v-for="(item, index) in crumbs" :key="index">
     <span v-if="index != 0"><a :href="item.to">{{ item.text }}</a></span>
     <span v-else>{{ item.text }}</span>
    </li>    

  </ol>
</nav>
</template>

<script>
export default {
  computed: {
    crumbs: function() {
      let pathArray = this.$route.path.split("/")
      pathArray.shift()
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: this.$route.matched[idx].meta.breadCrumb || path,
        });
        return breadcrumbArray;
      }, [])
      return breadcrumbs;
    }
  }
};
</script>

<style>
.breadcrumb{
  background-color: #fff !important;
  margin-bottom: 0px !important;
  padding: 0px !important;
  font-size:0.75rem;
  font-weight: 500;
  color: #55595c;
}

.tituloBreadCrumb{
  margin-right: 8px;
}
</style>