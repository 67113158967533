<template>
  <div>
    <div class="row">
      <div id="Aracena" class="embalse col col-md-4">
        <div class="tituloWrapper">
          <h3 class="titulo">ARACENA</h3>
          <hr />
        </div>
        <div class="vaso">
          <div
            id="agua"
            class="agua"
            :style="{ height: datosEmbalse.Aracena.porcentaje + '%' }"
          ></div>
          <div id="vaso" class="vasoC"></div>
        </div>
        <div class="contenidoWraper">
          <div class="volumen">
            <p class="porcentajeNumero" id="volumenAracena">
              {{ datosEmbalse.Aracena.porcentaje
              }}<span class="porcentajeSimbolo align-text-top">%</span>
            </p>
            <br /><br />
          </div>
        </div>
        <div class="capacidad">
          <p>
            Capacidad:
            <span class="datos">{{ v_capacity.Aracena }} hm<sup>3</sup></span>
          </p>
          <p>
            Volumen Embalsado:
            <span class="datos"
              >{{ datosEmbalse.Aracena.volumen }} hm<sup>3</sup></span
            >
          </p>
        </div>
      </div>
      <div id="Zufre" class="embalse col col-md-4">
        <div class="tituloWrapper">
          <h3 class="titulo">ZUFRE</h3>
          <hr />
        </div>
        <div class="vaso">
          <div
            id="agua"
            class="agua"
            :style="{ height: datosEmbalse.Zufre.porcentaje + '%' }"
          ></div>
          <div id="vaso" class="vasoC"></div>
        </div>
        <div class="contenidoWraper">
          <div class="volumen">
            <p class="porcentajeNumero" id="volumenZufre">
              {{ datosEmbalse.Zufre.porcentaje
              }}<span class="porcentajeSimbolo align-text-top">%</span>
            </p>
            <br /><br />
          </div>
        </div>
        <div class="capacidad">
          <p>
            Capacidad:
            <span class="datos">{{ v_capacity.Zufre }} hm<sup>3</sup></span>
          </p>
          <p>
            Volumen Embalsado:
            <span class="datos"
              >{{ datosEmbalse.Zufre.volumen }} hm<sup>3</sup></span
            >
          </p>
        </div>
      </div>
      <div id="Minilla" class="embalse col col-md-4">
        <div class="tituloWrapper">
          <h3 class="titulo">LA MINILLA</h3>
          <hr />
        </div>
        <div class="vaso">
          <div
            id="agua"
            class="agua"
            :style="{ height: datosEmbalse['La Minilla'].porcentaje + '%' }"
          ></div>
          <div id="vaso" class="vasoC"></div>
        </div>
        <div class="contenidoWraper">
          <div class="volumen">
            <p class="porcentajeNumero" id="volumenMinilla">
              {{ datosEmbalse["La Minilla"].porcentaje
              }}<span class="porcentajeSimbolo align-text-top">%</span>
            </p>
            <br /><br />
          </div>
        </div>
        <div class="capacidad">
          <p>
            Capacidad:
            <span class="datos"
              >{{ v_capacity["La Minilla"] }} hm<sup>3</sup></span
            >
          </p>
          <p>
            Volumen Embalsado:
            <span class="datos"
              >{{ datosEmbalse["La Minilla"].volumen }} hm<sup>3</sup></span
            >
          </p>
        </div>
      </div>
      <div id="Gergal" class="embalse col col-md-4">
        <div class="tituloWrapper">
          <h3 class="titulo">EL GERGAL</h3>
          <hr />
        </div>
        <div class="vaso">
          <div
            id="agua"
            class="agua"
            :style="{ height: datosEmbalse['El Gergal'].porcentaje + '%' }"
          ></div>
          <div id="vaso" class="vasoC"></div>
        </div>
        <div class="contenidoWraper">
          <div class="volumen">
            <p class="porcentajeNumero" id="volumenGergal">
              {{ datosEmbalse["El Gergal"].porcentaje
              }}<span class="porcentajeSimbolo align-text-top">%</span>
            </p>
            <br /><br />
          </div>
        </div>
        <div class="capacidad">
          <p>
            Capacidad:
            <span class="datos"
              >{{ v_capacity["El Gergal"] }} hm<sup>3</sup></span
            >
          </p>
          <p>
            Volumen Embalsado:
            <span class="datos"
              >{{ datosEmbalse["El Gergal"].volumen }} hm<sup>3</sup></span
            >
          </p>
        </div>
      </div>
      <div id="Melonares" class="embalse col col-md-4">
        <div class="tituloWrapper">
          <h3 class="titulo">LOS MELONARES</h3>
          <hr />
        </div>
        <div class="vaso">
          <div
            id="agua"
            class="agua"
            :style="{
              height: datosEmbalse['Los Melonares'].porcentaje + '%',
            }"
          ></div>
          <div id="vaso" class="vasoC"></div>
        </div>
        <div class="contenidoWraper">
          <div class="volumen">
            <p class="porcentajeNumero" id="volumenMelonares">
              {{ datosEmbalse["Los Melonares"].porcentaje
              }}<span class="porcentajeSimbolo align-text-top">%</span>
            </p>
            <br /><br />
          </div>
        </div>
        <div class="capacidad">
          <p>
            Capacidad:
            <span class="datos"
              >{{ v_capacity["Los Melonares"] }} hm<sup>3</sup></span
            >
          </p>
          <p>
            Volumen Embalsado:
            <span class="datos"
              >{{ datosEmbalse["Los Melonares"].volumen }} hm<sup>3</sup></span
            >
          </p>
        </div>
      </div>
      <div id="Cala" class="embalse col col-md-4">
        <div class="tituloWrapper">
          <h3 class="titulo">CALA</h3>
          <hr />
        </div>
        <div class="vaso">
          <div
            id="agua"
            class="agua"
            :style="{ height: datosEmbalse.Cala.porcentaje + '%' }"
          ></div>
          <div id="vaso" class="vasoC"></div>
        </div>
        <div class="contenidoWraper">
          <div class="volumen">
            <p class="porcentajeNumero" id="volumenCala">
              {{ datosEmbalse.Cala.porcentaje
              }}<span class="porcentajeSimbolo align-text-top">%</span>
            </p>
            <br /><br />
          </div>
        </div>
        <div class="capacidad">
          <p>
            Capacidad:
            <span class="datos">{{ v_capacity.Cala }} hm<sup>3</sup></span>
          </p>
          <p>
            Volumen Embalsado:
            <span class="datos"
              >{{ datosEmbalse.Cala.volumen }} hm<sup>3</sup></span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import { mapState, mapActions } from "vuex";
import PrevisionService from "@/services/PrevisionService";

export default {
  props: {
    volumenesFinales: {
      type: Array
    },
     viewMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      datosEmbalse: {
        Aracena: {
          volumen: null,
          porcentaje: null,
        },
        "El Gergal": {
          volumen: null,
          porcentaje: null,
        },
        "Los Melonares": {
          volumen: null,
          porcentaje: null,
        },
        "La Minilla": {
          volumen: null,
          porcentaje: null,
        },
        Zufre: {
          volumen: null,
          porcentaje: null,
        },
        Cala: {
          volumen: null,
          porcentaje: null,
        },
      },
    };
  },
  components: {},
  computed: {
    ...mapState("planner", ["v_capacity"]),
  },
  watch: {
    volumenesFinales: function (newVal, oldVal) {
      this.getDatosEmbalse(newVal);
    },
  },
  methods: {
    ...mapActions("planner", ["v_updCapacity"]),
    //Este método rellena los datos de cada embalse menos la capacidad, que está en vuex.
    async getDatosEmbalse(newVal) {
      //Inicio de for clásico puesto que necesitamos los índices.
      //Recibe los volumenesFinales cuando el componente es instanciado en el padre.this.
      for (let i = 0; i < newVal.length; i++) {
        //Este switch es necesario para comprobar la nomenclatura de los embalses con nombres compuestos.
        switch (newVal[i].label) {
          case "Melonares":
            this.datosEmbalse["Los Melonares"].volumen = this.roundTwoDecimals(
              newVal[i].extraido.value
            );

            this.datosEmbalse["Los Melonares"].porcentaje =
              this.roundTwoDecimals(
                this.percentajeCalculator(
                  this.v_capacity["Los Melonares"],
                  this.datosEmbalse["Los Melonares"].volumen
                )
              );
            break;

          case "Minilla":
            this.datosEmbalse["La Minilla"].volumen = this.roundTwoDecimals(
              newVal[i].extraido.value
            );
            this.datosEmbalse["La Minilla"].porcentaje = this.roundTwoDecimals(
              this.percentajeCalculator(
                this.v_capacity["La Minilla"],
                this.datosEmbalse["La Minilla"].volumen
              )
            );
            break;

          case "Gergal":
            this.datosEmbalse["El Gergal"].volumen = this.roundTwoDecimals(
              newVal[i].extraido.value
            );
            this.datosEmbalse["El Gergal"].porcentaje = this.roundTwoDecimals(
              this.percentajeCalculator(
                this.v_capacity["El Gergal"],
                this.datosEmbalse["El Gergal"].volumen
              )
            );
            break;

          default:
            this.datosEmbalse[newVal[i].label].volumen =
              this.roundTwoDecimals(newVal[i].extraido.value);
            this.datosEmbalse[newVal[i].label].porcentaje =
              this.roundTwoDecimals(
                this.percentajeCalculator(
                  this.v_capacity[newVal[i].label],
                  this.datosEmbalse[newVal[i].label].volumen
                )
              );
            break;
        }
      }
    },
    roundTwoDecimals(num) {
      return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;
    },
    percentajeCalculator(capacidad, volumen) {
      return (parseFloat(volumen) / parseFloat(capacidad)) * 100;
    },
  },
  mounted() {
    
  },
};
</script>

<style scoped>
/* Clase para que las anotaciones tengan el mismo estilo que los Select*/
.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #333333;
}

.vaso {
  width: 100px;
  height: 120px;
  position: relative;
  float: left;
}
.agua {
  max-height: 90%;
  width: 99px;
  background: #9ad8f6;
  margin-left: 1px;
  position: absolute;
  bottom: 8px;
  -webkit-border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  border-radius: 0px 0px 5px 5px;
}
.vaso .vasoC {
  width: 100px;
  height: 120px;
  background: url(~@/assets/img/vaso.png) no-repeat;
  position: relative;
}
.embalse {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 20px;
  min-width: 200px;
}
.tituloWrapper {
  padding-bottom: 11px;
  margin-top: -2px;
}
.contenidoWraper {
  float: left;
  margin-left: 10px;
}
hr {
  max-width: 90%;
}
.titulo {
  font-size: 18px;
  color: rgb(51, 51, 51);
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: bold;
}
.volumen p {
  font-family: Helvetica, Arial, sans-serif !important;
}
.porcentajeNumero {
  font-size: 50px;
  font-weight: bold;
  color: rgb(34, 147, 197);
}
.porcentajeSimbolo {
  font-size: 62%;
  color: rgb(172, 172, 172);
  font-weight: normal;
}
.capacidad {
  float: left;
}
.capacidad p {
  font-size: 15px;
  color: rgb(51, 51, 51);
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: bold;
  margin-top: 10px;
  line-height: 0.5;
}

.capacidad .datos {
  color: rgb(34, 147, 197);
}
</style>