<template>
  <div class="row">
    <div class="col-12 col-sm-3 col-md-4 text-justify">
      <p class="tituloMensaje"></p>
    </div>
    <div class="col-12 col-sm-3 col-md ms-0">
      <Select
        v-model="selectedYear"
        id="yearSelector"
        icon="far fa-calendar-alt"
        class="cursor-pointer"
        label='* Seleccione el año del que desea recuperar el "Plan de Aducción" marcado
          como favorito'
        :options="yearsList"
        valKey="name"
        textKey="name"
      />
    </div>
  </div>
</template>


<script>
import Select from "@/components/commons/Select";
import PrevisionService from "@/services/PrevisionService";

export default {
  data() {
    return {
      selectedYear: null,
      yearsList: [],
    };
  },
  components: {
    Select,
  },
  watch: {
    selectedYear: function (newVal, oldVal) {
      if (newVal != null) {
        this.$emit("selectedYear", newVal);
      }
    },
  },
  computed: {},
  methods: {},
  async mounted() {
    let formOptions = await PrevisionService.getYearsPrevFavorita();
    if (formOptions.data) {
      //Populate years row
      this.yearsList = formOptions.data;
      this.selectedYear = await this.yearsList[0].name;
    } else {
      console.log(
        "No se pudo obtener datos desde el backend. Contacte con el desarrollador"
      );
    }
  },
};
</script>
