<template>
  <div class="w-100 maxWrapperWidth m-4">
    <div class="row mb-2">
      <Breadcrumb />
    </div>
    <!-- Selector de opciones básicas -->
    <div class="card">
      <div class="card-body optionsSelector">
        <FavoritePlanOptions
          ref="options"
          class="d-flex justify-content-center mt-2 optionsSelector-inside"
          @selectedYear="getFavoritePlan"
        />
      </div>
    </div>

    <div class="card accordion accordion-flush mt-3" id="accordionFlushExample">
      <!-- ITEM 1 del accordion -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            1. Configuración inicial usada
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
        >
          <div class="accordion-body p-0">
            <div>
              <!-- Selector de opciones básicas -->
              <div class="card mt-2">
                <div class="card-body">
                  <p class="titleWizard text-center mt-4">
                    <span class="circled-number">1</span> Seleccione los
                    parámetros iniciales para calcular el plan de aducción
                  </p>
                  <hr class="separador" />
                  <Options
                    ref="options"
                    class="d-flex justify-content-center mt-4"
                    :viewMode="true"
                  />
                </div>
              </div>

              <!-- Configuracion de volumenes, maximos y minimos -->
              <div class="card mt-2">
                <div class="card-body">
                  <p class="titleWizard text-center mt-4">
                    <span class="circled-number">2</span> Seleccione la
                    disponibilidad de los Embalses según la quincena
                  </p>
                  <hr class="separador" />
                  <Availability
                    class="p-4 mt-3"
                    :viewMode="true"
                    :altAvaDataset="altAvaDataset"
                  />
                </div>
              </div>
              <!-- Configuracion de volumenes, maximos y minimos -->
              <div class="card mt-2">
                <div class="card-body">
                  <p class="titleWizard text-center mt-4">
                    <span class="circled-number">3</span> Seleccione los
                    volúmenes de aducción máximos y mínimos de los Embalses
                  </p>
                  <hr class="separador" />
                  <div class="row col-12 cursor-pointer mb-1 mt-4">
                    <div class="col-3 offset-md-1">
                      <Select
                        v-model="selectedStartDate"
                        id="startDateSelector"
                        icon="fa-solid fa-calendar-day"
                        label="* Seleccione la fecha de inicio con la que desea calcular el plan"
                        :options="startDateList"
                        valKey="key"
                        textKey="name"
                        style="min-width: 475px"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <Volumes
                    id="vol0101"
                    ref="vol0101"
                    class="p-4 mt-4"
                    :viewMode="true"
                    selectedStartDate="01/01"
                  />
                  <Volumes
                    id="vol0111"
                    ref="vol0111"
                    v-if="selectedStartDate == '01/11'"
                    class="p-4 mt-4"
                    :viewMode="true"
                    selectedStartDate="01/11"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ITEM 2 del accordion -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="false"
            aria-controls="flush-collapseTwo"
          >
            2. Plan seleccionado
          </button>
        </h2>
        <div
          id="flush-collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingTwo"
        >
          <div class="accordion-body">
            <div class="grayscale">
              <Vasos
                :volumenesFinales="volumenesFinales"
                :viewMode="true"
                class="vasitosAgua"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- ITEM 3 del accordion -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree"
            aria-expanded="false"
            aria-controls="flush-collapseThree"
          >
            3. Producción energética
          </button>
        </h2>
        <div
          id="flush-collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingThree"
        >
          <div class="accordion-body">
            <div class="grayscale">
              <EnergyProduction
                ref="energyproduction"
                :dataSet="v_dataSet"
                :viewMode="true"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- ITEM 4 del accordion -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingFour">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseFour"
            aria-expanded="false"
            aria-controls="flush-collapseFour"
          >
            4. Procedencia de los volúmenes
          </button>
        </h2>
        <div
          id="flush-collapseFour"
          class="accordion-collapse collapse"
          aria-labelledby="flush-headingFour"
        >
          <div class="accordion-body">
            <div class="grayscale">
              <EnergySource
                ref="energysource"
                :dataSet="v_dataSet"
                :viewMode="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Metemos el contenido en un BlockFull, que le dará un aspecto de sección -->
    <BlockFull
      :titulo="'Plan de aducción para ' + v_selectedYear"
      :titleCol="6"
      class="mt-3"
    >
      <template v-slot:options class="col-2">
        <SavePlanButtons
          :redirectToFavorites="false"
          :is-favorite-view="true"
          :idPlanFavorite="favoritePlanId"
          :isReusable="true"
          @reusePlan="reusePlan"
        />
      </template>
      <template v-slot:content>
        <!-- INIT Muestreo de datos finales-->
        <BestSolutionTabs ref="bestSolutionTabs" :viewMode="false" />
        <!-- END Muestreo de datos finales-->
      </template>
    </BlockFull>
    <!-- Segunda fila -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FavoritePlanOptions from "./components/FavoritePlanOptions";
import PlannerService from "@/services/PlannerService";
import PrevisionService from "@/services/PrevisionService";
import Availability from "./../common/step1-preconfigure/Availability";
import Volumes from "./../common/step1-preconfigure/Volumes";
import Options from "./../common/step1-preconfigure/Options";
import Vasos from "./../common/step2-solutions/Vasos";
import BestSolutionTabs from "./../common/step3-bestplan/BestSolutionTabs";
import Breadcrumb from "@/layout/components/Breadcrumb";
import EnergyProduction from "./../common/step3-bestplan/EnergyProduction";
import EnergySource from "./../common/step3-bestplan/EnergySource";
import SavePlanButtons from "./../common/step3-bestplan//SavePlanButtons.vue";
import BlockFull from "@/components/commons/BlockFull";
import Select from "@/components/commons/Select";

export default {
  data() {
    return {
      volumenesFinales: null,
      altAvaDataset: [],
      favoritePlanId: null,
      selectedStartDate: null,
      startDateList: [],
      dataSetVolumesGen: null,
    };
  },
  components: {
    FavoritePlanOptions,
    Availability,
    Volumes,
    Options,
    BestSolutionTabs,
    Vasos,
    Breadcrumb,
    EnergyProduction,
    SavePlanButtons,
    BlockFull,
    EnergySource,
    Select,
  },
  computed: {
    ...mapState("planner", [
      "v_selectedYear",
      "v_dataSet",
      "v_capacity",
      "v_updSelectedStartDate",
    ]),
  },
  watch: {
    selectedYear: async function (newVal, oldVal) {
      /* Anti-redouble trigger */
      if (newVal != null && newVal != oldVal) {
        await this.getFavoritePlan(newVal);
      }
    },
    dataSetVolumesGen: function (newVal, oldVal) {
      //await this.v_updDataSetReusePlanVol({})
      //console.log(newVal)
      //this.v_updDataSetReusePlanVol(newVal);
    },
  },
  methods: {
    ...mapActions("planner", [
      "v_updSelectedYear",
      "v_updSelectedCriteria",
      "v_updDataSet",
      "v_updCapacity",
      "v_updVolumes",
      "v_updDataSetReusePlanVol",
      "v_updDataSetReusePlanAva",
      "v_updDataSetReuseSelectedStartDate",
    ]),
    // Reusar plan
    async reusePlan() {
      //console.log(await this.v_dataSet);
      await this.$router.push({
        name: "wizard-step-1",
        query: { reuseplan: "true" },
      });
    },
    updateVolumesValues(index, selectedDate, data) {
      this.dataSetVolumes[index][selectedDate] = data;
      this.v_updVolumes(this.dataSetVolumes);
    },
    // Tener el plan Favorito
    async getFavoritePlan(selectedYear) {
      let responseCapacities = await PrevisionService.getCapacities();
      if (responseCapacities) {
        let auxCapacities = {};
        responseCapacities.data.forEach((element) => {
          auxCapacities[element.Nombre] = element.Capacidad;
        });
        await this.v_updCapacity(auxCapacities);
      }

      // Obtener listado de fechas para volumenes
      let response_01 = await PlannerService.getFormsOptions();
      if (response_01.data) {
        // Populate selects
        this.startDateList = response_01.data.startDates;
      } else {
        console.log(
          "No se pudo obtener datos desde el backend. Contacte con el desarrollador"
        );
      }

      let response = await PrevisionService.getNewestFavoritePlanByYear(
        selectedYear
      );
      if (response.data) {
        //Guardamos el id para el upload
        this.favoritePlanId = response.data[0].Id;

        //Reconvertimos a los arrays necesarios
        let dataSet = {
          genExcelFilename: {},
          genPDFFilename: {},
          planAduccion: {
            columnas: [
              { colspan: "2", label: "", subLabels: ["Fecha", "Dias"] },
              {
                colspan: "2",
                label: "Total Aducido",
                subLabels: ["m3/s", "hm3"],
              },
            ],
            filas: [],
          },
          planReajustes: {
            columnas: [
              { colspan: "2", label: "", subLabels: ["Fecha", "Dias"] },
            ],
            filas: [],
          },
          planTrasvases: {
            columnas: [
              { colspan: "2", label: "", subLabels: ["Fecha", "Dias"] },
            ],
            filas: [],
          },
          planVolumen: {
            columnas: [
              { colspan: "2", label: "", subLabels: ["Fecha", "Dias"] },
            ],
            filas: [],
          },
        };

        let dataSetAvailability = new Array();

        /** Recorremos el array y metemos las columnas evitadno qeu se dupliquen **/
        /************************************************************************ */
        for (let item of response.data) {
          // Meter cabeceras en Plan Aduccion
          if (item.Total_Aducido_hm3 != null) {
            const itemExists = dataSet.planAduccion.columnas.some(
              (itemloc) => itemloc.label === item.Nombre_Embalse
            );
            if (!itemExists) {
              dataSet.planAduccion.columnas.push({
                colspan: "2",
                label: item.Nombre_Embalse,
                subLabels: ["m3/s", "hm3"],
              });
            }
          }
          // Meter cabeceras en planvolumenes
          if (item.Volumen_Embalsado_hm3 != null) {
            const itemExists = dataSet.planVolumen.columnas.some(
              (itemloc) => itemloc.label === item.Nombre_Embalse
            );
            if (!itemExists) {
              dataSet.planVolumen.columnas.push({
                colspan: "1",
                label: item.Nombre_Embalse,
                subLabels: ["hm3"],
              });
            }
          }
          // Meter cabeceras en planReajustes y planTrasvases
          if (item.Trasvases != null) {
            const itemExists = dataSet.planTrasvases.columnas.some(
              (itemloc) => itemloc.label === item.Nombre_Embalse
            );
            if (!itemExists) {
              dataSet.planTrasvases.columnas.push({
                colspan: "1",
                label: item.Nombre_Embalse,
                subLabels: ["hm3"],
              });
              dataSet.planReajustes.columnas.push({
                colspan: "1",
                label: item.Nombre_Embalse,
                subLabels: ["hm3"],
              });
            }
          }

          //Meter fechas en todos los casos
          const itemExists = dataSet.planAduccion.filas.some(
            (itemloc) => itemloc.fecha === item.Fecha
          );
          if (!itemExists) {
            if (dataSet.planAduccion.filas.length < 24) {
              dataSet.planAduccion.filas.push({
                fecha: item.Fecha,
                masasAgua: [
                  {
                    caudal: { value: "-" },
                    extraido: { value: "-" },
                    label: "Total Aducido",
                  },
                ],
                ndias: item.Dias,
              });
            }
            if (dataSet.planVolumen.filas.length < 25) {
              dataSet.planVolumen.filas.push({
                fecha: item.Fecha,
                masasAgua: [],
                ndias: item.Dias,
              });
            }
            if (dataSet.planReajustes.filas.length < 24) {
              dataSet.planReajustes.filas.push({
                fecha: item.Fecha,
                masasAgua: [],
                ndias: item.Dias,
              });
            }
            if (dataSet.planTrasvases.filas.length < 24) {
              dataSet.planTrasvases.filas.push({
                fecha: item.Fecha,
                masasAgua: [],
                ndias: item.Dias,
              });
            }
          }
        }

        /** Recorremos el array extrayendo los datos y rellenando los items inmediatos **/
        /****************************************************************************** */
        for (let item of response.data) {
          // Para rellenar el plan de aduccion
          let index = dataSet.planAduccion.filas.findIndex(
            (item2) => item2.fecha === item.Fecha
          );
          if (index != -1) {
            const itemExists = dataSet.planAduccion.columnas.some(
              (itemloc) => itemloc.label === item.Nombre_Embalse
            );
            if (itemExists) {
              if (index < 24) {
                dataSet.planAduccion.filas[index].masasAgua.push({
                  caudal: {
                    value: item.Total_Aducido_m3 ? item.Total_Aducido_m3 : "-",
                  },
                  extraido: {
                    value: item.Total_Aducido_hm3
                      ? item.Total_Aducido_hm3
                      : "-",
                  },
                  label: item.Nombre_Embalse,
                });
              }
            }
          }

          // Para rellenar el plan de volumen
          index = dataSet.planVolumen.filas.findIndex(
            (item2) => item2.fecha === item.Fecha
          );
          if (index != -1) {
            const itemExists = dataSet.planVolumen.columnas.some(
              (itemloc) => itemloc.label === item.Nombre_Embalse
            );
            if (itemExists) {
              if (index < 25) {
                dataSet.planVolumen.filas[index].masasAgua.push({
                  extraido: {
                    value: item.Volumen_Embalsado_hm3
                      ? item.Volumen_Embalsado_hm3
                      : "-",
                  },
                  label: item.Nombre_Embalse,
                });
              }
            }
          }

          // Para rellenar el plan de Trasvases y reajustes
          index = dataSet.planTrasvases.filas.findIndex(
            (item2) => item2.fecha === item.Fecha
          );
          if (index != -1) {
            const itemExists = dataSet.planTrasvases.columnas.some(
              (itemloc) => itemloc.label === item.Nombre_Embalse
            );
            if (itemExists) {
              if (index < 24) {
                dataSet.planReajustes.filas[index].masasAgua.push({
                  extraido: { value: item.Reajustes ? item.Reajustes : "-" },
                  label: item.Nombre_Embalse,
                });
                dataSet.planTrasvases.filas[index].masasAgua.push({
                  extraido: { value: item.Trasvases ? item.Trasvases : "-" },
                  label: item.Nombre_Embalse,
                });
              }
            }
          }
        }

        //////////////////////////////////////////////////////////////
        // Para rellenar la lista de volumenes
        //////////////////////////////////////////////////////////////
        let dataSetVolumes = new Array();

        //Una vez cargado, comprobamos si se guardaron datos de Volumenes Máximos y Mínimos diferentes
        let changeSelectedDate = false;
        for (let i = 0; i < response.data.length; i += 24) {
          if (
            response.data[i].Volumen_Maximo !==
              response.data[i + 20].Volumen_Maximo ||
            response.data[i].Volumen_Minimo !==
              response.data[i + 20].Volumen_Minimo
          ) {
            changeSelectedDate = true;
          }
          if (
            response.data[i + 1].Nombre_Embalse ==
            response.data[i].Nombre_Embalse
          ) {
            i++;
          }
        }
        //console.log(changeSelectedDate);
        this.selectedStartDate = changeSelectedDate ? "01/11" : "01/01";

        // Recorremos los volumenes para meterlos en el formato deseado
        const userPromises = response.data.map(async (item) => {
          const itemExists = dataSetVolumes.some(
            (itemloc) => itemloc.Nombre_Embalse === item.Nombre_Embalse
          );
          // Si el item no existe es que estamos accedino en los 01/01
          if (!itemExists) {
            if (item.Fecha == "01/01") {
              dataSetVolumes.push({
                Fecha: item.Fecha,
                Nombre_Embalse: item.Nombre_Embalse,
                Capacidad: this.v_capacity[item.Nombre_Embalse],
                Volumen_Maximo: item.Volumen_Maximo,
                Volumen_Minimo: item.Volumen_Minimo,
                "01/01": {
                  volMax: item.Volumen_Maximo,
                  volMin: item.Volumen_Minimo,
                },
              });
            }
          }
        });
  
        // Recorremos los volumenes para meterlos en el formato deseado
        const userPromises2 = response.data.map(async (item3) => {
          const itemExists = dataSetVolumes.some(
            (itemloc) => itemloc.Nombre_Embalse === item3.Nombre_Embalse
          );
          // Si el item no existe es que estamos accedino en los 01/01
         if (itemExists && changeSelectedDate) {
            // LOGICA PARA METER LAS 2 talbas
            if (item3.Fecha == "01/11") {
              //Buscamos el index donde esta almacenado
              let index11 = dataSetVolumes.findIndex(
                (item2) => item2.Nombre_Embalse == item3.Nombre_Embalse
              );
              if (index11 != -1) {
                dataSetVolumes[index11]["01/11"] = {
                  volMax: item3.Volumen_Maximo,
                  volMin: item3.Volumen_Minimo,
                };
              }
            }
          }  
        });

        await Promise.all(userPromises)
        await Promise.all(userPromises2)
        await this.v_updDataSetReusePlanVol(dataSetVolumes);


        /** Recorremos el array de plan de aduccion llenando finalmente el Total Aducido **/
        /*********************************************************************************/
        let sumCaudal = 0;
        let sumExtraido = 0;
        let indexArr = -1;
        for (let item of dataSet.planAduccion.filas) {
          for (let item2 of item.masasAgua) {
            if (item2.label != "Total Aducido") {
              sumCaudal =
                sumCaudal +
                parseFloat(item2.caudal.value != "-" ? item2.caudal.value : 0);
              sumExtraido =
                sumExtraido +
                parseFloat(
                  item2.extraido.value != "-" ? item2.extraido.value : 0
                );
            }
          }
          indexArr = item.masasAgua.findIndex(
            (item2) => item2.label === "Total Aducido"
          );
          item.masasAgua[indexArr].caudal.value = sumCaudal.toFixed(3);
          item.masasAgua[indexArr].extraido.value = sumExtraido.toFixed(3);
          sumCaudal = 0;
          sumExtraido = 0;
        }

        /** Recorremos el array para introducir los ordens que se nos marcaron al final del proyecto **/
        /*********************************************************************************************/
        let list = [
          "",
          "Total Aducido",
          "La Minilla",
          "Los Melonares",
          "El Gergal",
        ];

        // Ordenar Columnas PlanAduccion
        let auxArray = new Array(dataSet.planAduccion.columnas);
        let index = null;
        for (let i = 0; i < list.length; i++) {
          index = dataSet.planAduccion.columnas.findIndex(
            (item) => item.label == list[i]
          );
          auxArray[i] = dataSet.planAduccion.columnas[index];
        }
        dataSet.planAduccion.columnas = auxArray;

        let list2 = [
          "",
          "Aracena",
          "Zufre",
          "La Minilla",
          "El Gergal",
          "Cala",
          "Los Melonares",
        ];

        // Ordenar Columnas PlanVolumen
        auxArray = new Array(dataSet.planVolumen.columnas);
        index = null;
        for (let i = 0; i < list2.length; i++) {
          index = dataSet.planVolumen.columnas.findIndex(
            (item) => item.label == list2[i]
          );
          auxArray[i] = dataSet.planVolumen.columnas[index];
        }
        dataSet.planVolumen.columnas = auxArray;

        // Ordenar Columnas PlanReajustes y PlanTrasvases
        auxArray = new Array(dataSet.planReajustes.columnas);
        index = null;
        for (let i = 0; i < list2.length; i++) {
          index = dataSet.planReajustes.columnas.findIndex(
            (item) => item.label == list2[i]
          );
          auxArray[i] = dataSet.planReajustes.columnas[index];
        }
        dataSet.planReajustes.columnas = auxArray;
        dataSet.planTrasvases.columnas = auxArray;

        /* INIT Modificaicon dataSetvolumes para que se trague la estructura */
        /* END Modificaicon dataSetvolumes para que se trague la estructura*/
        // Actualización de todas las variables involucradas
        this.dataSetVolumesGen = await dataSetVolumes;
        // Cambiamos el año por el seleccionado
        this.v_updSelectedYear(selectedYear);
        // Cogemos el criterio de lo primero que veamos
        this.v_updSelectedCriteria(response.data[0].Criteria);
        // Actualizamos la lista de volumenes finales
        if (this.selectedStartDate == "01/01") {
          this.$refs.vol0101.populateFromExt(this.dataSetVolumesGen);
          this.v_updDataSetReuseSelectedStartDate(this.selectedStartDate);
        } else if (this.selectedStartDate == "01/11") {
          this.$refs.vol0101.populateFromExt(this.dataSetVolumesGen);
          this.$refs.vol0111.populateFromExt(this.dataSetVolumesGen);
          this.v_updDataSetReuseSelectedStartDate(this.selectedStartDate);
        }
        this.altAvaDataset = await response.data;
        this.v_updDataSetReusePlanAva(await response.data);
        this.volumenesFinales = await dataSet.planVolumen.filas[24].masasAgua;
        // Actualizamos el dataSet reactivo
        this.v_updDataSet(dataSet);
      } else {
        console.log("Error al recuperar los datos del plan favorito");
      }
    },
    //Funcion para copiar el objeto y que se deslinke la misma referencia en memoria
    copyObject(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
  },
};
</script>




<style scoped>
.titleWizard {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  font-size: 0.9rem;
  font-weight: 700;
  color: #505d6a;
}

.circled-number {
  color: #6f92d1;
  border: 2px solid #1b68a5;
  border-radius: 50%;
  font-size: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
}

.separador {
  margin: auto;
  max-width: 70%;
  opacity: 0.2;
}

.grayscale {
  opacity: 0.7;
  filter: grayscale(70%);
}

.overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.accordion-button {
  /*background-color: #e7f1ff;*/
}

.optionsSelector {
  position: relative;
}
.optionsSelector::before {
  content: "";
  position: absolute;
  background-image: url("~@/assets/img/favorite.svg");
  background-repeat: no-repeat;
  background-size: auto 250%;
  background-position: -80px;
  top: 0;
  left: 0;
  opacity: 0.2;
  width: 100%;
  height: 100%;
}

.optionsSelector-inside {
  /* This will make it stack on top of the ::before */
  position: relative;
}

.vasitosAgua {
  zoom: 1;
}

@media (max-width: 476px) {
  .vasitosAgua {
    zoom: 0.7;
  }
}
</style>